/*



self::PREORDER_STATUS_ID => '#000',
self::PAID_STATUS_ID => '#16a085',
self::REJECTED_STATUS_ID => '#000',
self::STATUS_UNSUBSCRIBED => '#000',
self::STATUS_EXPIRED => '#000',

 */

span.badge.bg-social_services{
    background-color:#f41717 !important;
}
span.badge.bg-special_needs{
    background-color:#2470ff !important;
}
span.badge.bg-completed{
    background-color:#c7d6e0 !important;
    color: #000;
}
span.badge.bg-discount{
    background-color:#c3f926 !important;
    color: #000;
}
span.badge.bg-rejected{
    background-color:#000 !important;
    color: #fff;
}
span.badge.bg-pending_paid{
    background-color:#f39c12 !important;
    color: #fff;
}
span.badge.bg-unsubscribed,
span.badge.bg-preorder,
span.badge.bg-expired
{
    background-color:rgb(168, 0, 72) !important;
    color: #fff;
}

span.badge.bg-paid{
    background-color:#2ed573 !important;
    color: #fff;
}