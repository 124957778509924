.activityCard{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 15px 50px 0;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.22);
    border-radius: 8px;
}

.activityCard img{
    width: 100%;
    height: 400px;
}

.activityCard h3.name{
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #09101D;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.activityCard .purple-btn{
    margin-top:24px;
}

.activityCard .body{
    padding: 24px;
}
.activityCard .description{
    padding-top: 12px;
}
