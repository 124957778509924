#footer{
    padding:24px 0;
    color: #fff;
    margin-top:80px;
    background-color: #222222;
    min-height: 282px;
}

#footer a{
    color: #fff;
}

#footer span.heading{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display:block;
}

#footer span{
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    display:block;
    padding:10px 0;
}
