#cart table.cartTable{
    border: 1px solid rgba(168, 0, 72, 0.25);
}

#cart span.totalAmount{
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    color: #A80048;
    margin-top:72px;
    margin-bottom:24px;
}