div#mobileMenuIcon{
    margin-left:20px;
}

div#mobileMenuIcon .mobileBars{
    font-size:28px;
    color: #FFF;
}

div#mobileMenu.show{
    display:block;
}

div#mobileMenu{
    min-width: 250px;
    display:none;
    z-index:98;
    position:fixed;
    height:100vh;
    background:#fff;
    border-right:1px solid #CECECE;
    box-shadow: 0 0 15px rgba(0,0,0,0.75);
    clip-path: inset(0px -15px 0px 0px);
    padding:20px 0 40px 0;
    overflow-y: auto;
}

div#mobileMenu .close{
    display:block;
    float:right;
    margin-right:25px;
    font-size:22px;
}

div#mobileMenu a.menu-item{
    display:block;
    padding:11px 40px;
    font-weight: bold;
}

div#mobileMenu .menu-subitem{
    padding-left: 60px !important;
    font-weight: normal !important;
}

div#mobileMenu .logoutHr{
    margin-top:50px;
}
