@media only screen and (max-width: 575px) {
    .body-container{
        min-height: 300px !important;
    }
    #footer{
        text-align:center;
    }
    .activityCard{
        margin: 0 15px 50px 15px !important;
    }
    .login-square{
        margin-top:30px;
    }

    .shoppingCartIcon {
        margin-right:30px !important;
    }

    #activityDetailUserView{
        h1{
            text-align:center;
        }
    }
}

@media only screen and (max-width: 768px) {
    #login #sendLoginFormBtn,
    #signup #sendSignupFormBtn
    {
        display:block;
        width:100%;
        margin-top:25px;
    }

    #footer #footerLogoLeft,
    #footer #footerLogoRight
    {
        margin-top:35px;
    }

    .login-square{
        margin-top:30px;
    }

    #activityDetailUserView{
        h1{
            text-align:center;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    #footer #footerLogoLeft,
    #footer #footerLogoRight
    {
        margin-top:35px;
    }
}

@media only screen and (max-width: 991px) {
    #dashboard .button{
        width: 100%;
    }
}
