.header{
    padding: 0 48px;
}

.header .signin{
    color:#A80048;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    padding-bottom:10px;
}
.header .signin a{
    color:#A80048;
    text-decoration: none;
}

.header .createAccount{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #8E8E93;
}

.login-square{
    padding-top:30px;
    height: 120px;
    width: 236px;
    left: 0px;
    top: 0px;
    box-sizing: border-box;
    border: 4px solid #A80048;
    border-radius: 12px;
    text-align: center;
    display:flex;
    flex-direction: column;
}

.login-square .logout{
    font-size:20px;
    font-weight: bold;
}

.template-red-bar {
    height: 48px;
    background: #A80048;
    margin: 23px 0 0 0;
}

.shoppingCartIcon {
    padding-top:12px !important;
}
.shoppingCartIcon svg{
    font-size:25px;
}
.shoppingCartIconCircle{
    position:absolute;
    color: #fff;
    background: #E86C00;
    width:16px;
    height:16px;
    border-radius: 50%;
    font-size:12px;
    text-align: center;
    line-height: 15px;
    margin-left:13px;
    margin-top:-13px;
}

div.navbar-item{
    width:auto;
    margin-left: 48px;
}
div.navbar-item a{
    color:#FFF;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}
div.navbar-item-wrapper{
    padding-top:12px;
}

div.navbar-item .btn-primary{
    --bs-btn-focus-shadow-rgb: none;
}

div.navbar-item .dropdown .dropdown-toggle{
    background:none;
    border:none;
    padding:0;
    font-weight: bold;
    font-size:16px;
    line-height: 19px;
    margin-top:-2px;
}

div.navbar-item .dropdown .dropdown-menu{
    border-radius: 5px;
    margin-top:5px;
}

div.navbar-item .dropdown .dropdown-menu a.dropdown-item{
    color:#A80048;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin: 8px auto;
}
div.navbar-item .dropdown .dropdown-menu a.dropdown-item:hover{
    background:transparent;
}
