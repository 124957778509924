#dashboard .button{
    padding: 14px 18px;
    gap: 8px;
    height: 56px;
    min-width: 350px;

    /* Sanse Purple */
    background: #A80048;
    border-color: #A80048;
    border-radius: 8px;
}

#dashboard .button:hover,
#dashboard .button:focus,
#dashboard .button:active,
#dashboard .button:disabled
{
    background-color: #820138;
    border-color: #820138;
    color:#FFF;
}

#dashboard .buttons{
    margin: 48px auto;
}
