#activity_detail{
    strong{
        color:#c3512f;
    }
}

#activity_detail h1{
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 40px;
}

#activity_detail .formContainer{
    margin-top:24px;
}

.activityImageContainer{
    margin-top:-350px;
}

.activityBgImage{
    height: 448px;
    background-position: center center;
}

.activityImage{
    width: 400px;
    height:400px;
}

#activity_detail .price{
    font-size:30px;
    font-weight:bold;
}
