.rc-pagination-item-active,
.rc-pagination-item-active:hover,
.rc-pagination-item-active:active,
.rc-pagination-item-active:enabled,
.rc-pagination-item-active:focus,

.rc-pagination-prev:hover,
.rc-pagination-prev:active,
.rc-pagination-prev:enabled,
.rc-pagination-prev:focus,

.rc-pagination-next:hover,
.rc-pagination-next:active,
.rc-pagination-next:enabled,
.rc-pagination-next:focus,

.rc-pagination-item:hover,
.rc-pagination-item:active,
.rc-pagination-item:enabled,
.rc-pagination-item:focus
{
    border: 1px solid #A80048;
    color: #000;
}

.rc-pagination-item-active a,
.rc-pagination-item-active:hover a,
.rc-pagination-item-active:active a,
.rc-pagination-item-active:enabled a,
.rc-pagination-item-active:focus a
{
    color: #000;
}
