#invoiceDetailRequest .yourRequestLabel{
    margin-top:47px;
    margin-bottom: 24px;
}

#invoiceDetailRequest .infoalert{
    background: rgba(217, 217, 217, 0.25);
    border: 1px solid rgba(217, 217, 217, 0.5);
    border-radius: 5px;
    padding: 14px 24px 18px 24px;
    margin-top:36px;
    font-size:14px;
}

#invoiceDetailRequest .infoalert .title{
    font-size:16px;
}

#invoiceDetailRequest .personalDataInfo{
    font-size:14px;
}

#mainRequestDetail .alert-client{
    background: #EEF2FA;
    border: 1px solid #2E5AAC;
}
