.body-container{
    min-height: 600px;
    padding-top: 36px;
}

a{
    color: #A80048;
    text-decoration: none;
}
a:hover{
    color: #A80048;
    text-decoration: underline;
}

.purple{
    color: #A80048;
}

.pointer:hover{
    cursor:pointer;
}

table.redTable{
    border: 1px solid rgba(168, 0, 72, 0.25);
}

.my-24{
    margin:24px auto;
}

.upload-widget{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 66px 80px;
    gap: 6px;
    text-align:center;
    max-width: 100%;

    background: #FFFFFF;
    border: 1px dashed #454545;
    border-radius: 8px;
}

div.is-invalid div.css-13cymwt-control{
    border:1px solid #DC3545;
}

.is-invalid-div{
    border:1px solid red !important;
}

.customBadge{
    &{
        width:100%;
        max-width: 350px;
        border-radius: 5px;
        padding:3px 10px;
        font-size:14px;
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
    }

    /** completed, rejected, paid (green and red bg) **/
    &.status_5, &.status_6, &.status_7{
        color:#FFF;
    }
}

.text-underline{
    text-decoration: underline;
}

.ml0Important{
    margin-left:0 !important;
}
.paddingFileDropzoneFileTypeInfoText{
    display:block;
    margin-top:10px;
}