div.loading-overlay-wrapper div.loading-overlay{
    width:100%;
    height:100vh;
    position:fixed;
    background: #000;
    opacity: 0.4;
    z-index:99;
}

div.loading-overlay-wrapper div.text{
    color: #A80048;
    border: 4px solid #A80048;
    background: #FFF;
    position: fixed;
    z-index: 999;
    width:400px;
    height:150px;
    font-weight: bold;
    opacity: 1;
    text-align: center;
    text-transform: uppercase;
    top:50%;
    left:50%;
    margin-top:-75px;
    margin-left:-200px;
    display:flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}