#login h1{
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 40px;
}

#login #pwdgroup{
    padding-top:10px;
}

#login .recoveryLink{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-top:25px;
    color: #A80048;
}
